import React from 'react';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { connect } from 'react-redux';
import Avatar from './Avatar';
import CompanyName from './CompanyName';
import { Hidden } from '@material-ui/core';
import { getUserByUid } from '../../../common/members/actions';
import DisplayName from './DisplayName';
import CompanyAvatar from './CompanyAvatar';
import theme from '../../assets/css/theme';

class User extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.state = {
			user: {},
			userTitle: null,
			userTrades: null,
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData(props, nextProps) {
		const { getUserByUid, selectedProjectId, projectMembers } = nextProps;
		const { user } = this.state;

		let newStateChanges = {};
		if (!nextProps.userId) return null;

		let isViewer = nextProps.userId === (nextProps.viewer || {}).id;

		if (nextProps.userId && !nextProps.getNested(['members', nextProps.userId])) getUserByUid(nextProps.userId);
		else if (
			props.userId != nextProps.userId ||
			(!isViewer &&
				props.getNested(['members', nextProps.userId]) != nextProps.getNested(['members', nextProps.userId])) ||
			(isViewer && props.getNested(['viewer']) != nextProps.getNested(['viewer']))
		) {
			newStateChanges.user = nextProps.getNested(['members', nextProps.userId], {});
			newStateChanges.userCompanyId =
				newStateChanges.getNested(['user', 'projects', selectedProjectId, 'companyId']) ||
				newStateChanges.getNested(['user', 'companyId']);
			if (newStateChanges.user.title) {
				const userTitle = nextProps.getNested(['members', nextProps.userId, 'projects', selectedProjectId, 'title'], nextProps.getNested(['members', nextProps.userId, 'title'])) 
				newStateChanges.userTitle = nextProps.getNested(['titles', userTitle, 'getTitle']);
			}
			if (newStateChanges.user.trades) {
				newStateChanges.userTrades = Object.keys(newStateChanges.user.projects?.[selectedProjectId]?.trades || newStateChanges.user.trades)
					.map(currTradeId => {
						let currTrade = nextProps.getNested(['trades', currTradeId, 'getTitle']);
						if (currTrade) return currTrade;
					})
					.filter(Boolean)
					.join(', ');
			}
		}

		if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
	}

	onClick() {
		const { onClick } = this.props;
		if (onClick) onClick();
	}

	render() {
		const { user, userTrades, userTitle, shadow, userCompanyId } = this.state;
		const {
			avatarSize,
			hideAvatar,
			userId,
			hideTitleAndTrades,
			detailedOverflow,
			companyMode,
			hideCompany,
			rtl,
			style,
		} = this.props;

		let minSize = 25;
		let fontSize = theme.fontSize < (avatarSize || minSize) / 2 ? theme.fontSize : (avatarSize || minSize) / 2;
		let styles = {
			main: {
				overflow: detailedOverflow ? 'visible' : 'hidden',
				...theme.defaultFont,
				fontSize: 12,
				fontWeight: theme.strongBold,
				textOverflow: 'ellipsis',
				margin: '1px',
				whiteSpace: 'nowrap',
				lineHeight: (avatarSize || minSize) / 2 + 'px',
			},
			sub: {
				overflow: detailedOverflow ? 'visible' : 'hidden',
				...theme.subFont,
				fontSize: 12,
				fontWeight: theme.bold,
				textOverflow: 'ellipsis',
				margin: '1px',
				whiteSpace: 'nowrap',
				lineHeight: (avatarSize || minSize) / 2 + 'px',
			},
		};

		if (companyMode)
			return (
				<div
					style={{
						display: 'flex',
						flex: 1,
						alignItems: 'center',
						marginTop: 5,
						marginBottom: 5,
						...(style || {}),
					}}
					onClick={this.onClick}
				>
					{Boolean(!hideAvatar) && (
						<div
							style={{
								width: avatarSize,
								minWidth: minSize,
								maxWidth: 50,
								[rtl ? 'marginLeft' : 'marginRight']: 7,
							}}
						>
							<CompanyAvatar companyId={userCompanyId} shadow={shadow} />
						</div>
					)}
					<div style={{ flex: 1, overflow: 'hidden' }}>
						<Hidden xsDown>
							<CompanyName style={styles.main} companyId={userCompanyId} />
							<DisplayName style={styles.sub} userId={userId} />
						</Hidden>
					</div>
				</div>
			);

		return (
			<div
				style={{
					display: 'flex',
					flex: 1,
					alignItems: 'center',
					marginTop: 5,
					marginBottom: 5,
					...(style || {}),
				}}
				onClick={this.onClick}
			>
				{Boolean(!hideAvatar) && (
					<div
						style={{
							width: avatarSize,
							minWidth: minSize,
							maxWidth: 50,
							[rtl ? 'marginLeft' : 'marginRight']: 7,
						}}
					>
						<Avatar type='user' src={(user || {}).avatar} shadow={shadow} />
					</div>
				)}
				<div style={{ flex: 1, overflow: 'hidden' }}>
					<Hidden xsDown>
						<DisplayName style={styles.main} userId={userId} />
						{Boolean(!hideCompany) && <CompanyName style={styles.sub} companyId={userCompanyId} />}
						{Boolean(!hideTitleAndTrades) && (
							<p style={styles.sub}>
								{userTitle}
								{Boolean(userTitle && userTrades) && ' - '}
								{userTrades}
							</p>
						)}
					</Hidden>
				</div>
			</div>
		);
	}
}

const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			viewer: state.users.viewer,
			trades: state.trades.map,
			titles: state.titles.map,
			rtl: state.app.rtl,
			members: state.members.map,
		}),
		{ getUserByUid },
	),
);
export default enhance(User);
