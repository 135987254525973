import reportsMessages from '../../common/reports/reportsMessages.js';
import { hideLoading } from '../app/actions';
import { getAppState } from '../configureMiddleware';
import { upsertForm } from '../forms/funcs';
import ExtraError from '../lib/errors/extraError';
import { exportFormPDF } from '../pdf/actions';

import { debugParams } from '../lib/utils/utils';

import { onError } from '../app/funcs';

import trackPosts, { POST_EVENTS } from './trackPosts.js';

export function exportPostsPDF(posts, projectId, viewer, onLateResponse, timeout = null) {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      let success = false;
      try {
        if (!(getAppState && getAppState() && getAppState().getNested(['app', 'isConnected'], false))) {
          onError({
            errorMessage: 'Failed to export posts pdf due to no signal',
            methodMetaData: {
              name: 'exportPostsPDF',
              args: [posts, projectId, viewer],
            },
            alertParams: {
              title: reportsMessages.exportErrors.title,
              message: reportsMessages.exportErrors.content,
            },
          });
          dispatch(hideLoading());
        }

        let project = getState().projects.map.getNested([projectId]);
        let localDate = new Date().getTime();
        let updatedReport = {
          createdAt: localDate,
          formTemplateId: '-postTestForm',
          type: 'general',
        };

        posts.loopEach(
          (key, currPost) =>
            (updatedReport = updatedReport.setNested(
              ['posts', currPost.isIssue ? 'issues' : 'records', currPost.id],
              true
            ))
        );

        let form = (await upsertForm(projectId, viewer, updatedReport)).form;
        let pdf = await dispatch(
          await exportFormPDF({
            viewer,
            project,
            formId: form.id,
            formType: 'general',
            isListenerMode: true,
            onLateResponse,
            timeout,
          })
        );

        if (pdf?.error) {
          throw pdf.error;
        }

        success = true;
        return pdf;
      } catch (error) {
        console.log(error);
        throw new ExtraError('updateReport error', null, error);
      } finally {
        const multi = posts.length > 1;
        let trackPostEventParams = { success, multi, projectId };
        if (posts.length == 1) {
          trackPostEventParams.post = posts[0];
        } else {
          trackPostEventParams.posts = posts;
        }
        dispatch({ type: POST_EVENTS.EXPORT_POST_AS_PDF, payload: trackPostEventParams });
        trackPosts(POST_EVENTS.EXPORT_POST_AS_PDF, trackPostEventParams);
      }
    };
    return {
      type: POST_EVENTS.EXPORT_POST_AS_PDF, //TODO: only for hide loading
      payload: getPromise(),
    };
  };
}
