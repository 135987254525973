import {
  getUtilsConfigObject,
  getAppState,
  apiListenersDeps} from "../../configureMiddleware";
import _ from "lodash";
import { platformActions } from "../../platformActions";
import { getFromRealm, saveToRealm } from "../realm/funcs";
import moment from "moment-timezone";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { batchDispatch, isEmptyValue, onError } from '../../app/funcs';
import ClientServerConnectivityManager, { getUniqueServiceId } from '../ClientServerConnectivityManager';
import { getClientLastUpdatesIO, getPathInfoFromSubsParams } from '../io/serverSocket';
import { getLocalLastUpdates, getClientMSConfig, saveLocalLastUpdates } from '../../lastUpdatesV2/funcs';
import { connectionManagerUpdate } from '../../app/actions';
import { isIssuePage } from '../../../web/views/Posts/utils';
import DataManagerInstance from "../../dataManager/DataManager";
import { dataManagerStates, dataManagerStatuses } from "../../dataManager/DataManagerConstants";
import mime from 'mime';


const isProd = process.env.NODE_ENV == 'production';
export const debugParams = {
  disableSaveToStorage: !isProd && false,
  disableMixpanel: !isProd && false,
  disableFirebaseFetch: !isProd && false,
  disableFirebaseListener: !isProd && false,
  disableRetries: !isProd && false,
  disableFetchByTSSave: !isProd && false,
  disableGetLastUpdateTS: !isProd && false,
  disableProjectTabNavigationStateParamsUpdate: !isProd && false
}

export const writeLogOnce = (() => {
  let writtenLogs = {};
  return (level, message, extraData) => {
    const messageKey = `${extraData?.scope} - ${extraData.scopeId} - ${extraData?.type} - ${message}`;
    if (!writtenLogs[messageKey]) {
      writtenLogs[messageKey] = true;
      platformActions.sentry.getSentry().metrics.increment(message, 1, { tags: { ...extraData, level } });
    }
  }
})();

export const threadSleep = function (delay) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve(true);
    }, delay);
  });
};

const envolopeProgressManager = {
  inProgress: {},
  setInProgress: function (subsciptionParams, isRevoke, inProgress) {
    const key = getUniqueServiceId(subsciptionParams) + isRevoke;
    if (inProgress) {
      this.inProgress[key] = inProgress;
    } else {
      delete this.inProgress[key];
    }
  },
  isInProgress: function (subsciptionParams, isRevoke) {
    let subjects = [true, false];
    if (isRevoke !== undefined) subjects = [isRevoke];
    const uniqueServiceId = getUniqueServiceId(subsciptionParams);
    return subjects.some(isRevoke => {
      const key = uniqueServiceId + isRevoke;
      return this.inProgress[key];
    });
  }
}
window.__envolopeProgressManager = envolopeProgressManager;


/**
 * 
 * @param {ScopeParams} scopeParams 
 * @param {ResourceParams} resourceParams 
 * @param {(data: any, isRevoke: boolean) => any} onEnvelope 
 * @param {boolean} immediateFetch 
 * @param {import('../ClientServerConnectivityManager').Viewer} viewer 
 * @returns 
 */

const getDataFromServer = async (scopeParams, resourceParams, onEnvelope, immediateFetch, viewer) => {
  const { scope, scopeId } = scopeParams;
  const { resourceName, queryParams, getLastUpdateTS, isObjectUseTransactionalDB } = resourceParams;
  const scopeIdIdentifier = scope === 'projects' || scope === 'global' ? 'projectId' : 'companyId';
  let result;
  const { fetchCompressedDataFromServer } = apiListenersDeps;

  const getData = async (_lastUpdateTS = undefined, isImmediateFetch = false) => {
    let res;
    try {
      res = await fetchCompressedDataFromServer(
        resourceName,
        { 
          ...queryParams,
          lastUpdateTS: !isEmptyValue(_lastUpdateTS) ? _lastUpdateTS + 1 : undefined,
          [scopeIdIdentifier]: scopeId,
          invokedByListener: isImmediateFetch ? false : true,
        },
        FETCH_DATA_TIMEOUT,
      );
      
      let isConnectionViewerVisible = (getAppState?.()?.getNested?.(["app", "connectionViewerVisiblity", "visible"]))
      if (platformActions.app.isWeb() && isConnectionViewerVisible) {
        batchDispatch([connectionManagerUpdate({ status: dataManagerStatuses.DATA_RECEIVED, scope, scopeId, subject: resourceName, subjectName: queryParams?.subjectName, size: isConnectionViewerVisible ? JSON.stringify(res || {}).length: undefined})]);
      }

    } catch (err) {
      if (typeof err === 'string' && err.includes('404')) {
        return null;
      }
      platformActions.sentry.notify('Failed getting data from server', {
        error: err,
        methodMetaData: {
          name: 'getDataFromServer',
          args: { scopeParams, resourceParams, immediateFetch, viewer },
        },
      });
      throw err;
    }

    return res;
  }
  
  try {
    const subscriptionParams = {
      scope,
      scopeId,
      subject: resourceName,
      params: queryParams,
    };
    ClientServerConnectivityManager.viewer = viewer;
    ClientServerConnectivityManager.socket = getClientLastUpdatesIO();
    ClientServerConnectivityManager.registerService(
      subscriptionParams,
      {
        onConnectionEstablished: (didFetch) => {
          if (!didFetch) {
            onEnvelope(null, false);
          }
        },
        onEnvelope: async ({ isRevoke }) => {
          const isRevokeFetchInProgress = envolopeProgressManager.isInProgress(subscriptionParams, true);
          const isRegularFetchInProgress = envolopeProgressManager.isInProgress(subscriptionParams, false);
          if (isRegularFetchInProgress || isRevokeFetchInProgress) {
            if (isRevoke && isRevokeFetchInProgress) {
              return;
            } else if ((isRevoke && isRegularFetchInProgress) || !isRevoke) {
              return;
            }
          }

          envolopeProgressManager.setInProgress(subscriptionParams, isRevoke, true);
          try {
            writeLogOnce('info', 'getDataFromServer - onEnvelope - get apiServer', { viewerId: viewer?.id, scope, scopeId, type: resourceName, resourceName, queryParams, isRevoke });

            await onEnvelope(
              await getData(
                isRevoke 
                  ? (getLastUpdateTS ? 0 : undefined)
                  : getLastUpdateTS?.()
              ),
              isRevoke,
            );
            
            const subsParamsFBInfo = getPathInfoFromSubsParams(ClientServerConnectivityManager.viewer, subscriptionParams, isRevoke);
            const { collection } = subsParamsFBInfo;
            const type = isRevoke ? 'revokes' : 'lastUpdates';
            
            const lastUpdateAvailable = getLocalLastUpdates({ type, scopeId: scopeId || scope, subject: collection })[0]?.lastUpdateAvailable;
            let dataToSet = { lastUpdated: lastUpdateAvailable };
            if (isObjectUseTransactionalDB) {
              dataToSet.lastSavedUpdate = lastUpdateAvailable;
            }
            
            await saveLocalLastUpdates(type, scopeId || scope, collection, dataToSet);
          } finally {
            envolopeProgressManager.setInProgress(subscriptionParams, isRevoke, false);

            DataManagerInstance.updateStatus({
              scope: subscriptionParams.scope,
              scopeId: subscriptionParams.scopeId,
              subject: subscriptionParams.subject,
              subjectParams: subscriptionParams.params,
              stateType: dataManagerStates.CONNECTION_STATUS,
              status: dataManagerStatuses.DATA_RECEIVED,
            });
          }

        }
      }
    );
    
    if (immediateFetch) {
      result = await getData(queryParams?.lastUpdateTS, true);
      DataManagerInstance.updateStatus({ scope, scopeId, subject: resourceName, subjectParams: queryParams, stateType: dataManagerStates.CONNECTION_STATUS, status: dataManagerStatuses.DATA_RECEIVED });
    }
  } catch (err) {
    onError({
      error: err,
      errorMessage: 'Failed getting data from server',
      methodMetaData: {
        name: 'getDataFromServer',
        args: { scopeParams, resourceParams, immediateFetch, viewer },
      },
    });
  }

  return result;
}

/**
 * @typedef {Object} ResourceParams
 * @property {string} [resourceName]
 * @property {boolean} [forceMSClientConfig]
 * @property {string} [firebasePath]
 * @property {Record<string, any>} [queryParams]
 * @property {import('../offline-mode/config').SchemaInfos} [schemaInfo]
 * @property {() => number} [getLastUpdateTS]
 * @property {string} [uniqueKey] - used to differentiate between different services with the same scope, scopeId, subject and params -> it also means that the service is not binded to the last updates docs. The fetches and such wont't affect the last updates docs
 * @property {boolean} [isObjectUseTransactionalDB] - defines if we can trust the save function to have saved the data locally directly - We need this because when saving to the reducer, we want this the saveToStorage to confirm the save of the data locally - Should be removed once we save everything to realm / loki
 * 
 * @typedef {{ scope: import('../ClientServerConnectivityManager').Scope, scopeId?: string }} ScopeParams
 * 
 * @param {ScopeParams} scopeParams
 * @param {ResourceParams} resourceParams 
 * @param {(data: any) => any} onEnvelope
 * @param {import('../ClientServerConnectivityManager').Viewer} viewer 
 * @param {boolean} [immediateFetch] 
 */
export const getSnapshotData = async (scopeParams, resourceParams, onEnvelope, viewer, immediateFetch) => {
  const { scope, scopeId } = scopeParams;
  const { resourceName, firebasePath, forceMSClientConfig, queryParams } = resourceParams;
  const clientMSConfig = getClientMSConfig(scope, scopeId)?.getNested?.(['bulk', resourceName.split('/')[0]]);

  if (isFetchInProgress(scopeId, resourceName, resourceName, queryParams)) {
    return;
  }

  setFetchInProgress(scopeId, resourceName, resourceName, true, queryParams);
  let result;
  try {
    if (forceMSClientConfig || clientMSConfig?.isActive) {
      result = await getDataFromServer(
        scopeParams, 
        resourceParams, 
        onEnvelope, 
        immediateFetch, 
        viewer,
      );
    } else if (firebasePath) {
      result = await firebaseGet(firebasePath);
      DataManagerInstance.updateStatus({ scope, scopeId, subject: resourceName, subjectParams: queryParams, stateType: dataManagerStates.CONNECTION_STATUS, status: dataManagerStatuses.DATA_RECEIVED });
      writeLogOnce('info', 'getSnapshotData - get Firebase', { viewerId: viewer?.id, scope, scopeId, type: resourceName, resourceName, queryParams });
    }
  } catch (err) {
    onError({
      errorMessage: 'Failed getting snapshot data',
      error: err,
      methodMetaData: {
        name: 'getSnapshotData',
        args: { scopeParams, resourceParams, viewer },
      },
    });
  } finally {
    setFetchInProgress(scopeId, resourceName, resourceName, false, queryParams)
  }

  return result;
}

const getSnapshotData_listeners = {};

export async function replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, realmSchemaName, quary, subjectName) {
  // Check if the new maxLastUpdateTS is saved in db, and if not (In case it was from the isDeletd - Replace the current max)
  if (currBatchMaxLastUpdateTS && (realm.objects(realmSchemaName).filtered(quary).max('updatedTS') < currBatchMaxLastUpdateTS)) {
    let currObjects = realm.objects(realmSchemaName).filtered(quary).sorted('updatedTS', true);
    if (currObjects.length) {
      let maxCurrObject = currObjects[0];
      realm.create(realmSchemaName, { ...maxCurrObject.realmToObject(), updatedTS:currBatchMaxLastUpdateTS }, 'modified');
    }
  }
}

const sortedStringify = obj => _.isObject(obj) ? JSON.stringify(obj, Object.keys(obj).sort()) : obj;
const generateResourceFullName = (resourceName, subjectName, query) => `${resourceName}_${subjectName || ""}_${sortedStringify(query)}`;


const MAX_FETCH_TIME = 60 * 1000;
const isFetchInProgress = (scopeId, resourceName, subjectName, queryParams) => {
  let fetchStartTS = _.get(this, ['isFetchingInProgress', scopeId, generateResourceFullName(resourceName, subjectName, queryParams)]);
  return (fetchStartTS && (Date.now() - fetchStartTS < MAX_FETCH_TIME));
}

const setFetchInProgress = (scopeId, resourceName, subjectName, status, queryParams) => {
  _.set(this, ['isFetchingInProgress', scopeId, generateResourceFullName(resourceName, subjectName, queryParams)], status ? Date.now() : null);
}



var allListeners = {};

/**
 *
 * @param {string} projectId
 * @param {string} firebasePath
 * @param {import('firebase').database.EventType} eventType
 * @param {function} onUpdate - callback called with the update
 * @returns {Promise<function>} function to call to remove the listener
 */

export const startProjectFirebaseListener = (
  projectId,
  firebasePath,
  eventType,
  onUpdate
) => {
  const { firebaseDatabase } = apiListenersDeps;
  if (!_.get(allListeners, [projectId, firebasePath])) {
    _.set(allListeners, [projectId, firebasePath], true);
    
    const ref = firebaseDatabase().ref(firebasePath);
    ref.on(
      eventType,
      (snapshot) => Boolean(onUpdate) && onUpdate(snapshot.val(), snapshot.key, snapshot)
    );
  }

  return () => {
    endProjectListener(projectId, firebasePath);
  };
};

export const endProjectListener = (projectId, firebasePath) => {
  const { firebaseDatabase } = apiListenersDeps;
  if (!_.get(allListeners, [projectId, firebasePath])) 
    return;

  const ref = firebaseDatabase().ref(firebasePath);
        ref.off("value");
        ref.off("child_added");
        ref.off("child_changed");
  _.set(allListeners, [projectId, firebasePath], false);
}

export function endAllProjectListeners(projectId) {
  const { firebaseDatabase } = apiListenersDeps;

  Object.keys(allListeners[projectId] || {}).forEach((firebasePath) => {
    const ref = firebaseDatabase().ref(firebasePath);
          ref.off("value");
          ref.off("child_added");
          ref.off("child_changed");
    _.set(allListeners, [projectId, firebasePath], false);
  });
}

let bouncerCollector = {};
const BOUNCER_TIMING = 400; // ms
const firebaseListenerCallback = ({ resource, event, saveFunc, data, scopeId, useDebouncer = true }) => {
  let val = data.val();
  if (val.id) {
    if (_.get(resource, ['schemaInfo', 'schemaName'])) {
      const local = getLocal({
        idsToGet: [val.id],
        projectId: scopeId,
        schemaType: _.get(resource, ['schemaInfo', 'schemaType']),
        schemaName: _.get(resource, ['schemaInfo', 'schemaName']),
        query: 'isLocal == TRUE AND lastUploadTS == 0'
      });

      const isWaitingToBeUploaded = !_.isEmpty(_.get(local, ['objects'], {}));
      if (isWaitingToBeUploaded)
        return;

    }

    const resourceName = resource?.name || 'default';
    if (useDebouncer && false) {
			debouncedSaveFunc({ scopeId, resourceName, resource, saveFunc, data: val, event });
		} else {
			saveFunc({ '0': val }, undefined, resourceName, event, resource.subjectName);
		}
  }
}

const debouncedSaveFunc = ({ scopeId, resourceName, resource, saveFunc, data, lastUpdateTS, event = 'fetchByTS' }) => {
	const bouncerCollectorRootPathArr = [scopeId, resourceName, resource.subjectName || '', event];
	const objectsPathArr = [...bouncerCollectorRootPathArr, 'objects'];
	const debouncedSaveFunction = AwesomeDebouncePromise(
		() => {
			const objectsToSave = _.get(bouncerCollector, objectsPathArr);
			if (isEmptyValue(objectsToSave)) return;
			_.set(bouncerCollector, objectsPathArr, {}); // clean before save in case save takes a while and other calls come in
			saveFunc(objectsToSave, undefined, resourceName, event, resource.subjectName);
		},
		BOUNCER_TIMING,
		{ key: (resourceName = '', subjectName = '') => `${resourceName}-${subjectName}` },
	);

	_.set(bouncerCollector, [...objectsPathArr, data.id], data);

	return debouncedSaveFunction(resourceName, resource.subjectName);
};

const FETCH_DATA_TIMEOUT = 45 * 1000;

export const saveLocal = ({
  objectsToSave,
  lastUpdateTStypeId,
  projectId,
  schemaName,
  schemaType,
  preProcessObjectForLocalSaveFunc = null,
}) => {
  if (Object.keys(objectsToSave).length && projectId) {
    if (platformActions.app.isWeb()) {
      const localDB = platformActions.localDB.getCementoDB();
      localDB.set(schemaType, objectsToSave);
    } else {
      notifyLongFunctionDuration(() => saveToRealm({
        objectsToSave, lastUpdateTStypeId, projectId, schemaName, schemaType,
        preProcessObjectForLocalSaveFunc,
      }), `saveToRealm ~ The function took too long`, `utils`)
    }
  }
};

export const removeLocal = ({
  objectsToRemove,
  projectId,
  schemaType,
}) => {
  if (_.size(objectsToRemove) && projectId) {
    let removeQuery;
    if (platformActions.app.isWeb()) {
      removeQuery = { 
        $and: [
          { id: { $in: _.values(objectsToRemove).map(obj => obj.id) } },
          { projectId }
        ]
      };
    } else {
      removeQuery = `projectId == "${projectId}" AND (${_.values(objectsToRemove).map(obj => `id == "${obj.id}"`).join(' OR ')})`;
    }
    const localDB = platformActions.localDB.getCementoDB();
    localDB.unset(schemaType, removeQuery);
  }
}

/**
 *
 * @param {{
 *  idsToGet?: string[],
 *  projectId: string,
 *  schemaType: 'propertyInstances' | 'posts' | 'checklistItemInstances' | 'equipment' | 'employees',
 *  schemaName: 'post24' | 'equipment1' | 'employee1' | 'propertyInstance1' | 'checklistItemInstance1',
 *  query?: string,
 * }} param0
 * @returns
 */

export const getLocal = ({
  idsToGet = [],
  projectId,
  schemaName,
  schemaType,
  query = "",
}) => {
  // TODO: update query mechanism so its more robust
  let objects = {};
  if (platformActions.app.getPlatform() !== "web")
    objects = getFromRealm({
      idsToGet,
      projectId,
      schemaName,
      schemaType,
      query,
    }).objects;

  return { objects };
};

/**
 * @param {string} [path]
 * @returns {string} New firebase id
 */
export const getUniqueFirebaseId = (path = "uniqueIds") => {
  const { firebaseDatabase } = getUtilsConfigObject();
  return firebaseDatabase().ref(path).push().key;
};

/**
 * Removes nested "isLocal" property
 * @param {any} object
 * @param {boolean} [shouldCloneObject] - If true, will clone the object with lodash.cloneDeep function
 */
export const removeNestedIsLocal = (object, shouldCloneObject = true) => {
  if (_.isNil(object) || typeof object !== "object") return object;

  if (shouldCloneObject) object = _.cloneDeep(object);

  const mapFunc = (value, key) => {
    delete (value || {}).isLocal;
    return removeNestedIsLocal(value, false);
  };

  if (Array.isArray(object)) return object.map(mapFunc);
  else return _.mapValues(object, mapFunc);
};

/**
 * @param {string} path - Firebase path to get
 * @returns {Promise<any>}
 */
export const firebaseGet = async (path, returnSnapshot = false) => {
  const { firebaseDatabase } = apiListenersDeps;
  let ret = null;

  if (path) {
    ret = (await firebaseDatabase().ref(path).once("value"))
    if (!returnSnapshot) {
      ret = ret.val();
    }
  }

  return ret;
};

/**
 * @typedef GetRoundedDateReturn
 * @property {number} day - day of month (1-31)
 * @property {number} month - month of year (1-12)
 * @property {number} year - year
 * @property {number} timestamp - UTC timestamp representing 00:00 AM of this date
 * @param {Date} [date]
 * @returns {GetRoundedDateReturn}
 */

export const getRoundedDate = (date = new Date(), timezone = moment.tz.guess()) => {
  const currMoment = moment.tz(date, timezone);
  const roundedMoment = currMoment.utc().clone().startOf('day');

  return {
    day: roundedMoment.date(),
    month: roundedMoment.month() + 1,
    year: roundedMoment.year(),
    timestamp: roundedMoment.valueOf(), 
    date: roundedMoment.toDate(),
  };
};

// TODO: nail down the type cause for now it doesnt understand that the array return can container any kind of type
/**
 * @template T
 * @param {<C>(() => T extends C)[] | Promise<T>[]} actions
 * @returns {T extends Promise ? Promise<{ data: T | T[] | null, error: any | null }> : { data: T | T[] | null, error: any | null }}
 */
export const tryCatch = (...actions) => {
  if (actions[0] instanceof Promise) return promiseWrapper(...actions);
  else {
    let data = [];
    try {
      actions.forEach((action) => {
        try {
          data.push(action());
        } catch (err) {
          throw err;
        }
      });

      return { data: actions.length === 1 ? data[0] : data, error: null };
    } catch (err) {
      return { data: null, error: err };
    }
  }
};

/**
 * @template T
 * @param {Promise<T>[]} promises
 * @returns {Promise<{ data: T | T[] | null, error: any | null }>}
 */
export const promiseWrapper = (...promises) => {
  return Promise.all(...promises)
    .then((data) => ({
      data: promises.length === 1 ? data[0] : data,
      error: null,
    }))
    .catch((error) => ({ data: null, error }));
};

export const prepareFirebaseObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

export const removeEmpty = (obj, uniqStringForDebugPurposes, _parentObject, _pathInParentObject = []) => {

  _.keys(obj).forEach((key) => {

    if (obj[key] && typeof obj[key] === "object") {
      return removeEmpty(obj[key], uniqStringForDebugPurposes, _parentObject ? _parentObject : obj, [..._pathInParentObject, key]);
    }
    else if (obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
};

export const removeNilAndEmpty = (obj) => {
  let stack = [[obj, null, null]]; 

  while (stack.length > 0) {
    let [current, parent, key] = stack.pop();

    if (current && typeof current === 'object' && !Array.isArray(current)) {
      Object.keys(current).forEach((k) => {
        if (current[k] === null || current[k] === undefined) {
          delete current[k];
        } else if (typeof current[k] === 'object') {
          stack.push([current[k], current, k]);
        }
      });

      if (parent && Object.keys(current).length === 0) {
        delete parent[key];
      }
    }
  }

  return obj;
};


export const convertDateToTS = val => moment(val, moment.ISO_8601, true).isValid()
  ? new Date(val).getTime()
  : val;

export const notifyLongFunctionDuration = async function (functionCall, notificationMessage, notificationLabel, notifyTimeThreshold = 5000) {
  const startTS = Date.now();
  const result = await functionCall();
  const endTS = Date.now();
  const duration = endTS - startTS;
  if (duration > notifyTimeThreshold)
    platformActions.sentry.notify(notificationMessage, { name : notificationLabel, duration, maxValidDuration : notifyTimeThreshold })

  return result;
}

export const getTargetObject = ({ allProps, objectId, subjectType,options,contentType, itemType }) => {
  if (options?.isCreation && subjectType === 'posts') {
    return {
			isIssue: isIssuePage(contentType, itemType),
			mode: 'draft',
		};
  }
  if (allProps?.[objectId]) {
    return Object.assign({}, allProps[objectId], {
      instances: allProps[objectId].instances || allProps[objectId].props,
    });
  }
  return {};
};

export const defineCardType = (subjectType) => {
  let cardType;

  switch (subjectType) {
    case 'forms':
      cardType = 'forms';
      break;
    case 'members':
      cardType = 'member';
      break;
    case 'companies':
      cardType = 'company';
      break;
    case 'locationsGroupsManagement':
      cardType = 'locationsGroup';
      break;
    case 'posts':
      cardType = 'post';
      break;
    default:
      cardType = 'connectedObjectProperties';
  }

  return cardType;
};
export const getRelevantLocalInstancesByPropId = (updatedPropertiesMap, objectId) => {
  let localInstancesByPropertyMap = {};

  Object.values(updatedPropertiesMap).forEach((propInstance) => {
    if (propInstance.parentId !== objectId) return;
    localInstancesByPropertyMap[propInstance.propId] = propInstance;
  });

  return localInstancesByPropertyMap;
};

export const hasNonImageType = (accept) => {
  const types = accept.split(',');

  for (const type of types) {
    const isImage = type.includes('image')
    if (!isImage) return true;
  }
  return false;
};
/**
 * @typedef {{ getData: (lastUpdateTS?: number) => any, subject: string } & ResourceParams} SubscribeToLastUpdatesResourceParams Extends ResourceParams with getDate
 */

/**
 * 
 * @param {ScopeParams} scopeParams 
 * @param {SubscribeToLastUpdatesResourceParams} resourceParams  
 * @param {(data: any, isRevoke: boolean) => any} onEnvelope 
 * @param {boolean} immediateFetch 
 * @param {import('../ClientServerConnectivityManager').Viewer} viewer 
 * @returns 
 */

export const subscribeToLastUpdates = async (viewer, scopeParams, resourceParams, onEnvelope, immediateFetch = false) => {
  const { scope, scopeId } = scopeParams;
  const { subject, queryParams, getLastUpdateTS, isObjectUseTransactionalDB, getData, uniqueKey } = resourceParams;
  let result;
  try {
    const subscriptionParams = {
      scope,
      scopeId,
      subject: subject,
      params: queryParams,
      uniqueKey,
    };
    ClientServerConnectivityManager.viewer = viewer;
    ClientServerConnectivityManager.socket = getClientLastUpdatesIO();
    ClientServerConnectivityManager.registerService(subscriptionParams, {
      onConnectionEstablished: (didFetch) => {
        if (!didFetch) {
          onEnvelope(null, false);
        }
      },
      onEnvelope: async ({ isRevoke }) => {
        const isRevokeFetchInProgress = envolopeProgressManager.isInProgress(subscriptionParams, true);
        const isRegularFetchInProgress = envolopeProgressManager.isInProgress(subscriptionParams, false);
        if (isRegularFetchInProgress || isRevokeFetchInProgress) {
          if (isRevoke && isRevokeFetchInProgress) {
            return;
          } else if ((isRevoke && isRegularFetchInProgress) || !isRevoke) {
            return;
          }
        }

        envolopeProgressManager.setInProgress(subscriptionParams, isRevoke, true);
        try {
          const lastUpdatedTS = isRevoke ? (getLastUpdateTS ? 0 : undefined) : getLastUpdateTS?.(); // if isRevoke why not 0?
          const data = await getData(lastUpdatedTS);
          await onEnvelope(data, isRevoke);

          const subsParamsFBInfo = getPathInfoFromSubsParams(
            ClientServerConnectivityManager.viewer,
            subscriptionParams,
            isRevoke
          );

          const { collection } = subsParamsFBInfo;
          const type = isRevoke ? 'revokes' : 'lastUpdates';

          const lastUpdateAvailable = getLocalLastUpdates({ type, scopeId: scopeId || scope, subject: collection })[0]
            ?.lastUpdateAvailable;
          let dataToSet = { lastUpdated: lastUpdateAvailable };
          if (isObjectUseTransactionalDB) {
            dataToSet.lastSavedUpdate = lastUpdateAvailable;
          }

          await saveLocalLastUpdates(type, scopeId || scope, collection, dataToSet);
        } finally {
          envolopeProgressManager.setInProgress(subscriptionParams, isRevoke, false);

          DataManagerInstance.updateStatus({
            scope: subscriptionParams.scope,
            scopeId: subscriptionParams.scopeId,
            subject: subscriptionParams.subject,
            subjectParams: subscriptionParams.params,
            stateType: dataManagerStates.CONNECTION_STATUS,
            status: dataManagerStatuses.DATA_RECEIVED,
          });
        }
      },
    });

    if (immediateFetch) {
      result = await getData(queryParams?.lastUpdateTS || getLastUpdateTS?.() || 0);
      DataManagerInstance.updateStatus({ scope, scopeId, subject, subjectParams: queryParams, stateType: dataManagerStates.CONNECTION_STATUS, status: dataManagerStatuses.DATA_RECEIVED });
    }
  } catch (err) {
    onError({
      error: err,
      errorMessage: 'Failed getting data from server',
      methodMetaData: {
        name: 'subscribeToLastUpdates',
        args: { scopeParams, resourceParams, immediateFetch, viewer },
      },
    });
  }

  return result;
};


export const unsubscribeToLastUpdates = (scopeParams, resourceParams) => {  
  const { scope, scopeId } = scopeParams;
  const { subject, queryParams: params, uniqueKey } = resourceParams;
  return ClientServerConnectivityManager.unregisterService({
    scope,
    scopeId,
    subject,
    params,
    uniqueKey,
  });
}

export const getAllExtensions = (mediaType) => {
  const extensions = [];
  
  for (const [type, innerExtensions] of Object.entries(mime._extensions)) {
    if (type.startsWith(mediaType)) {
      extensions.push(innerExtensions);
      if (innerExtensions.includes('jpeg')) {
        extensions.push('jpg');
      }
    }
  }

  return [...new Set(extensions)];
};

export const getMediaTypeFromExtension = (extension) => {
  const mimeType = mime.getType(extension);

  let mimeTypes = {}

  if (mimeType.startsWith('video/')) mimeTypes.isVideo = true;
  else if (mimeType.startsWith('image/')) mimeTypes.isImage = true;

  return mimeTypes;
}

export const parseObject = (obj) => {
  try {
    if (obj.realmToObject) {
      return JSON.parse(JSON.stringify(obj.realmToObject()));
    }
    return JSON.parse(JSON.stringify(obj));
  } catch (error) {
    return obj;
  }
}
