const lastDeploy = ` ------------------------------------------------------------------------- 
 ------------------------------------------------------------------------- 
      Welcome to cemento! Last update - 26/12/2024, 15:36:56
      Version No.  0.99.5.2.99
 ------------------------------------------------------------------------- 
 ------------------------------------------------------------------------- 
`;
const date = '26/12/2024, 15:36:56';
const version = '0.99.5.2.99';

module.exports = {
    lastDeploy,
    date,
    version,
};
