import { connect } from 'react-redux';
import { compose } from 'recompose';
import { dataManagerStates, IS_DATA_READY } from '../../common/dataManager/DataManagerConstants';
import { useContext } from 'react';
import { ProjectContext } from '../../common/projects/contexts';

export const DataManagerTable = (props) => {
  const { loadingReducer } = props;
  const { selectedProjectId } = useContext(ProjectContext);

  const reducerState = loadingReducer.toJS();

  const getPathFromReducer = (scope, subject, projectId, key) => {
    if (projectId) return reducerState[scope][projectId][subject][key];
    else return reducerState[scope][subject][key];
  };

  const getStates = (scope, subject, projectId) => {
    const isReady = getPathFromReducer(scope, subject, projectId, IS_DATA_READY);
    const storageStatus = getPathFromReducer(scope, subject, projectId, dataManagerStates.STORAGE_STATUS);
    const connectionStatus = getPathFromReducer(scope, subject, projectId, dataManagerStates.CONNECTION_STATUS);

    return { isReady, storageStatus, connectionStatus };
  };

  return (
    <div style={styles.containerStyle}>
      {Object.keys(reducerState).map((scope) => {
        let subjects = null;

        if (scope === 'projects') subjects = Object.keys(reducerState[scope][selectedProjectId]);
        else subjects = Object.keys(reducerState[scope]);

        return subjects.map((subject) => {
          const projectId = scope === 'projects' ? selectedProjectId : null;
          const { isReady, storageStatus, connectionStatus } = getStates(scope, subject, projectId);
          return (
            <div key={scope + subject + projectId}>
              <DataItemHeader scope={scope} subject={subject} isReady={isReady} projectId={projectId} />
              <DataItemValue isReady={isReady} storageStatus={storageStatus} connectionStatus={connectionStatus} />
            </div>
          );
        });
      })}
    </div>
  );
};

const DataItemHeader = ({ scope, subject, isReady, projectId }) => {
  return (
    <div style={{ display: 'block', fontWeight: 800, color: isReady ? 'lightgreen' : '#FF6A6F' }}>
      {scope.toUpperCase()} - {subject}
      {projectId && <div>{projectId}</div>}
    </div>
  );
};

const DataItemValue = ({ isReady, storageStatus, connectionStatus }) => {
  return (
    <>
      <div>Is Data ready: {isReady ? 'True' : 'False'}</div>
      <div>Storage Status: {storageStatus}</div>
      <div>Connection Status: {connectionStatus}</div>
    </>
  );
};

const styles = {
  containerStyle: {
    background: '#2D2D30',
    color: '#FFEEEE',
    width: '400px',
    height: '100%',
    overflow: 'scroll',
    position: 'fixed',
    textAlign: 'left',
    paddingInline: '15px',
    top: '60px',
    left: 0,
    paddingBottom: '80px',
    paddingTop: '15px',
    zIndex: 10000,
  },
};

const enhance = compose(
  connect(
    (state) => ({
      loadingReducer: state.dataManager,
    }),
    {}
  )
);
export default enhance(DataManagerTable);
