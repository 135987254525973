import loki from 'lokijs';
import { onError } from '../../app/funcs';
import { getLokiDexieInstance } from '../../../web/webActions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

////////////////////////////////////////
////////////////  PUBLIC  //////////////

//cementoOn
//cementoOff
//cementoInsert
//cementoUpsert
//cementoFind

////////////////////////////////////////
////////////////////////////////////////


loki.Collection.prototype.cementoInvocationList = {};
loki.Collection.prototype.cementoBouncerMap = {};
loki.Collection.prototype.cementoEventFunc = function() {
  if (!this.cementoBouncerMap[this.name]) {
    this.cementoBouncerMap[this.name] = AwesomeDebouncePromise(this.cementoBouncer.bind(this), 1000);
  }
  this.cementoBouncerMap[this.name]();
}

loki.Collection.prototype.cementoBouncer = function() {
  Object.values(this.cementoInvocationList[this.name] || {}).forEach(func => func(this.name));
}

loki.Collection.prototype.cementoOn = function(listenerName, callback) {
  if (!this.cementoInvocationList[this.name]) {
    this.cementoInvocationList[this.name] = { [listenerName]: callback };
    this.addListener(['insert','update','delete'], this.cementoEventFunc);
  } else {
    this.cementoInvocationList[this.name][listenerName] = callback; 
  }
}

loki.Collection.prototype.cementoOff = function(listenerName) {
  let ref = this.cementoInvocationList[this.name]
  if (ref)
    delete ref[listenerName];
  if (Object.values(this.cementoInvocationList[this.name] || {}) == 0) {
    delete this.cementoInvocationList[this.name]
    this.removeListener(['insert','update','delete'], this.cementoEventFunc);
  }
}

loki.Collection.prototype.cementoAllOff = function() {
  delete this.cementoInvocationList[this.name]
  this.removeListener(['insert','update','delete'], this.cementoEventFunc);
}

loki.Collection.prototype.cementoFind = function(query) {
  let result = this.find(query);
  let toRret = result.map(d => {
    let obj = { ...d };
    delete obj.$loki; 
    delete obj.meta;
    return obj;
  });
  return toRret; 
}

loki.Collection.prototype.cementoInsert = function(doc) {
  return this.insert(doc);
}

/**
 * 
 * @param {LokiQuery<LokiObj>} query 
 */
loki.Collection.prototype.cementoDelete = function(query) {
  this.findAndRemove(query)
}

loki.Collection.prototype.cementoFullDelete = async function(query) {
  let collectionName = this.name;
    if (!query)
      await getLokiDexieInstance().lokiPersistence.where({ collectionName }).delete();
    else {
      await getLokiDexieInstance().transaction('rw', getLokiDexieInstance().lokiPersistence, async () => {
          let all = [];
          let queryFilteredByProjects = {};
          let pers = await getLokiDexieInstance().lokiPersistence.where({ collectionName }).toArray();
          pers.forEach(p => all = all.concat(p.data));
          all.forEach(d => { 
            let queryApply = true;
            query.loopEach((k,v) => queryApply = queryApply && (d.getNested(String(k).split('.')) == v));
            if (!queryApply) {
              if (!queryFilteredByProjects[d.projectId])
                queryFilteredByProjects[d.projectId] = [];
              queryFilteredByProjects[d.projectId].push(d);
            }
          })
          let toPersist = [];

          await getLokiDexieInstance().lokiPersistence.where({ collectionName }).delete();
          queryFilteredByProjects.loopEach((projId, dataDocs) => toPersist = toPersist.concat(createPersistenceDocuments(dataDocs, projId, collectionName)));
          await getLokiDexieInstance().lokiPersistence.bulkPut(toPersist);
      });
    }
    this.findAndRemove(query);
}

loki.Collection.prototype.cementoUpsert = function(doc) {

  try {
    if (!doc) return null;
    
    let objectsToUpsertMap = {}
    let iteratable = Array.isArray(doc) ? doc : (doc.id ? [doc] : doc); 
    iteratable.loopEach((key, d) => objectsToUpsertMap[d.id] = d);

    let documents = this.find();
    documents.forEach((o => {
      if (objectsToUpsertMap[o.id]) {
        let newObject = { ...o, ...objectsToUpsertMap[o.id], meta: o.meta, $loki: o.$loki};
        delete objectsToUpsertMap[o.id]; 
        this.update(newObject);
      }
    }).bind(this));

    let newObjectToInsert = Object.values(objectsToUpsertMap);
    if (newObjectToInsert.length > 0)
      this.insert(newObjectToInsert);
    
    return true;
  }
  catch (e) {
    console.log(e);
    return false;
  }
}

const DEFAULT_PAGING = 5000;
const createPersistenceDocuments = (dataDocsToPersist, projectId, collectionName) => {
  const documentTemplate = { collectionName, projectId };
  const idBuilder = (pageNum = 0) => `${projectId}_${collectionName}_${pageNum}`;
  
  let paging = DEFAULT_PAGING;
  let ret = [];
  for (let i = 0; i < dataDocsToPersist.length; i += paging)
    ret.push(Object.assign({}, documentTemplate, { id: idBuilder(i), data: dataDocsToPersist.slice(i, i + paging), page: i }));

  return ret;
}

loki.prototype.saveProjectDataToStorage = async function(projectId) {
  let error = null;
  if (this.collections && this.collections.length > 0) {
    let allPersistence = [];
    this.collections.map(c => {
      let currCollection = this.getCollection(c.name);
      let documents = currCollection.cementoFind({ projectId });
      allPersistence = allPersistence.concat(createPersistenceDocuments(documents, projectId, c.name));
    });

    const dexieInstance = getLokiDexieInstance();
    try {
      await dexieInstance.transaction('rw', dexieInstance.lokiPersistence, async () => {
        try {
          await dexieInstance.lokiPersistence.bulkPut(allPersistence);
        } 
        catch (err) {
          error = err;
          throw error;
        }
      });
      if (error)
        throw error;
    }
    catch (err) {
      const isQuotaExceededError = Boolean(
        (err.name === 'QuotaExceededError')                    || 
        (err.inner && err.inner.name === 'QuotaExceededError') || 
        ((err.failures || []).length && err.failures.some(e => Boolean(e && e.name === 'QuotaExceededError')))
      );
      
      onError({
        errorMessage: 'Failed saving loki to dexie',
        error: err, 
        methodMetaData: {
          name: 'loki.prototype.saveProjectDataToStorage',
          args: { projectId },
        },
        errorMetaData: { isQuotaExceededError }
      });

      if (isQuotaExceededError) {
        try {
          await dexieInstance.lokiPersistence.where('projectId').noneOf(['global', projectId]).limit(10).delete();
          await this.saveProjectDataToStorage(projectId);
        }
        catch (err) {
          onError({
            methodMetaData: {
              name: 'loki.prototype.saveProjectDataToStorage',
              args: { projectId },
            },
            error: err,
            errorMessage: 'Failed to free up space',
            errorMetaData: { isQuotaExceeded: isQuotaExceededError },
          });
          throw err;
        }
      }
      else
        throw error;
    }
  }
}

const projectsLoadingIndicators = {};
loki.prototype.loadProjectDataFromStorage = async function(projectId, callback) {
  try {
    let loadedCollections = {};
    if (projectsLoadingIndicators[projectId]) {
      return;
    }
    projectsLoadingIndicators[projectId] = true;
    if (projectId) {
      await (getLokiDexieInstance().lokiPersistence.where({ projectId }).each(currPersistance => {
        loadedCollections[currPersistance.collectionName] = true;
        if (!this.getCollection(currPersistance.collectionName)) 
          this.addCollection(currPersistance.collectionName);

        this.getCollection(currPersistance.collectionName).cementoUpsert(currPersistance.data);
      }));

      if (callback) 
        callback(loadedCollections);
    }

    return loadedCollections;
  } catch (e) {
    console.log(e);
  } finally {
    projectsLoadingIndicators[projectId] = false;
  }
}

const projectsUnloadingIndicators = {};
loki.prototype.unloadProjectDataFromStorage = function(projectId, callback) {
  try {
    if (projectsUnloadingIndicators[projectId]) {
      return;
    }
    projectsUnloadingIndicators[projectId] = true;
    //return;
    let unloadedCollections = {};

    if (projectId && projectId != 'global') {
      this.collections.forEach(collection => {
        collection.cementoAllOff();
        unloadedCollections[collection.name] = true;
        collection.cementoDelete({ projectId });
      });

      if (callback)
        callback(unloadedCollections);
    }

    return unloadedCollections
  } catch (e) {
    console.log(e);
  } finally {
    projectsUnloadingIndicators[projectId] = false;
  }
}