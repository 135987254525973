import { Record } from '../transit';
import DataManagerInstance from './DataManager';
import { dataManagerEvents, IS_DATA_READY } from './DataManagerConstants';

const InitialState = Record(
  {
    global: {},
    companies: {},
    projects: {},
  },
  'dataManager',
  true
);
const initialState = new InitialState();

export default function dataManagerReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case dataManagerEvents.SET_DATA_READY: {
      const { subject, scopeParams, isDataReady } = action.payload;
      let path = [...scopeParams, subject];

      state = state.setIn([...path, IS_DATA_READY], isDataReady);

      return state;
    }

    case dataManagerEvents.SET_NEW_STATUS: {
      const { subject, scopeParams, stateType, newStatus, didFindData } = action.payload;

      let path = [...scopeParams, subject];

      if (!state.getIn(path)) {
        state = state.setIn(path, {});
      }

      let newResult = {
        ...state.getIn(path),
        [stateType]: newStatus,
      };

      const shouldSetDataReady = DataManagerInstance.getShouldSetDataReady({
        scope: scopeParams[0],
        status: newStatus,
        didFindData,
      });

      if (shouldSetDataReady) {
        newResult.isDataReady = true;
      }

      state = state.setIn([...path], newResult);
      return state;
    }

    case dataManagerEvents.UNSET_ALL_PROJECTS_DATA: {
      const { projectIds } = action.payload;
      for (const projectId of projectIds) {
        state = state.deleteIn(['projects', projectId]);
      }
      return state;
    }
  }

  return state;
}
