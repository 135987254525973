import { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { deepEqual } from '../../../app/funcs';
import { lokiInstance } from '../../../configureMiddleware';
import useMemoizedValue from '../../../hooks/useMemoizedValue';
import { v4 as uuidv4 } from 'uuid';
import useRefed from '../../../hooks/useRefed';
import { useParams } from 'react-router-dom';

const useLokiCompanies = (companiesFilters) => {
  // state
  const [companies, setCompanies] = useState({});
  const params = useParams();

  const selectedCompanyId = useMemo(() => {
    return params?.selectedCompanyId;
  },[params?.selectedCompanyId])

  // refs
  const lokiCompaniesRef = useRef(null);

  const _companiesFilters = useMemoizedValue(companiesFilters || {});

  const getCompaniesQuery = useCallback(() => {
    if (!_companiesFilters) return {};
    
    const { companyName, inIds } = _companiesFilters;

    let companiesQuery = {};

    if (inIds?.length) {
      companiesQuery['$or'] = inIds.map((id) => ({ id }));
    }
    if (companyName?.length) {
      companiesQuery['name'] = companyName;
    }

    return companiesQuery;
  }, [_companiesFilters?.companyName, _companiesFilters?.inIds]);

  const [query, setQuery] = useState(() => getCompaniesQuery());

  useEffect(() => {
    const newQuery = getCompaniesQuery();
    if (!deepEqual(newQuery, query)) {
      setQuery(newQuery);
    }
  }, [getCompaniesQuery]);

  const getCompaniesMap = useCallback(() => {
    let nextViewCompanies = [];

    if (lokiCompaniesRef.current) {
      nextViewCompanies = lokiCompaniesRef.current.cementoFind(query);
      nextViewCompanies.sort((a, b) => (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0));
    }

    return _.keyBy(nextViewCompanies, 'id');
  }, [query, lokiCompaniesRef.current]);

  const getCompaniesMapRef = useRefed(getCompaniesMap, [getCompaniesMap]);

  const companiesListener = useCallback((collectionName) => {
    if (collectionName === 'companies') {
      const map = getCompaniesMapRef.current(); // using a ref because the function is passed to a listener and otherwise would run with old params
      setCompanies(map);
    }
  }, []);

  // didMount
  useEffect(() => {
    let cleanUp;

    const lokiCompanies = lokiInstance.getCollection('companies');
    lokiCompaniesRef.current = lokiCompanies;

    const map = getCompaniesMapRef.current();
    setCompanies(map);

    const companiesListenerId = uuidv4();
    lokiCompanies.cementoOn(companiesListenerId, companiesListener);

    cleanUp = () => {
      lokiCompanies.cementoOff(companiesListenerId);
    };

    // willUnmount
    return cleanUp;
  }, [query, selectedCompanyId]);

  const memoizedValue = useMemo(
    () => ({
      companies, // Is only used on web, it is the filtered companies from the url query (is the same as currViewCompanies on native)
    }),
    [companies]
  );

  return memoizedValue;
};

export default useLokiCompanies;
