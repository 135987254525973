import { useSelector } from 'react-redux';
import { dataManagerStates, dataManagerStatuses, IS_DATA_READY } from '../dataManager/DataManagerConstants';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useMemoizedValue from './useMemoizedValue';
import { getAppState } from '../configureMiddleware';

const MAX_PROGRESS = 100;
const PROGRESS_STEP = 4;
const PROGRESS_INTERVAL = 50;
const FAST_PROGRESS_INTERVAL = 10;
export const useLoading = (subjectPaths) => {
  const loadingState = useSelector((state) => state.dataManager);

  const [progress, setProgress] = useState(0);

  const memoizedSubjectPaths = useMemoizedValue(subjectPaths);
  
  useEffect(() => {
    setProgress(0);
  },[memoizedSubjectPaths])

  const progressInterval = useRef(null);
  const graduallyUpdateProgress = (nextProgress) => {
    progressInterval.current && clearInterval(progressInterval.current);
    progressInterval.current = setInterval(() => {
      setProgress((prevProgress) => {
        const diff = nextProgress - prevProgress;
        const step = diff > 0 ? PROGRESS_STEP : 0;
        let next = prevProgress + step;
        if (next >= MAX_PROGRESS) {
          next = MAX_PROGRESS;
        }

        if (next === nextProgress) {
          clearInterval(progressInterval.current);
        }
        return next;
      });
    }, nextProgress === MAX_PROGRESS ? FAST_PROGRESS_INTERVAL : PROGRESS_INTERVAL);
  }

  useEffect(() => {
    if (!subjectPaths.length) {
      graduallyUpdateProgress(MAX_PROGRESS);
      return;
    }

    const isConnected = getAppState().getNested(['app', 'isConnected']);

    let readySubjects = 0;
    subjectPaths.forEach((subjectPath) => {
      const isSubjectLoadedFromStorage = _.get(loadingState, [...subjectPath, dataManagerStates.STORAGE_STATUS]) === dataManagerStatuses.STORAGE_LOADED;
      const isSubjectReady = _.get(loadingState, [...subjectPath, IS_DATA_READY]) === true;
      const needToConnect = isSubjectLoadedFromStorage && !isConnected && !isSubjectReady
      if (isSubjectReady || needToConnect) readySubjects++;
      return isSubjectReady;
    });

    const nextProgress = (readySubjects / memoizedSubjectPaths.length) * MAX_PROGRESS;

    graduallyUpdateProgress(nextProgress);
  }, [loadingState, memoizedSubjectPaths]);

  const isReady = progress === MAX_PROGRESS;

  return { isLoading: !isReady, progress, isReady };
};

export const withLoading =
  (getSubjectMapper) =>
  (WrappedComponent) => {
    const WithLoading = (props) => {
      const subjects = getSubjectMapper(props)
      const { isLoading, progress, isReady } = useLoading(subjects);
      return <WrappedComponent loadingProgress={progress} isDataLoading={isLoading} isDataReady={isReady} {...props} />;
    };

    // Set the display name for easier debugging
    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithLoading.displayName = `withLoading(${wrappedComponentName})`;

    return WithLoading;
  };
